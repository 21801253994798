import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import Protected from './views/Protected';
import Public from './views/Public';
import Mobile from './views/Mobile';
import Auth from './views/Auth';
import TermsPrivacy from './views/TermsPrivacy';

import 'react-toastify/dist/ReactToastify.min.css';
import GeolocationProvider from './providers/geolocation.provider';
import useFacebookTracker from './views/Fb';

const App = () => {
  useFacebookTracker();
  return (
    <GeolocationProvider>
      <ToastContainer />

      <Router>
        <Routes>
          <Route path="/public/*" element={<Public />} />
          <Route path="/mobile/*" element={<Mobile />} />
          <Route
            path="/terms-of-service/:lang?"
            element={<TermsPrivacy isTerms />}
          />
          <Route
            path="/privacy-policy/:lang?"
            element={<TermsPrivacy />}
          />
          <Route path="/auth/*" element={<Auth />} />
          <Route path="*" element={<Protected />} />
        </Routes>
      </Router>
    </GeolocationProvider>
  );
};

export default App;
