import React from 'react';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import * as Sentry from '@sentry/react';
import App from './App';
import * as serviceWorker from './serviceWorker';
import './i18n';
import store from './store';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import './styles/base.scss';
import './styles/image-viewer.scss';

import './config/api.config';

Sentry.init({
  dsn: import.meta.env.REACT_APP_SENTRY_DSN,
  release: import.meta.env.REACT_APP_SENTRY_RELEASE,
  environment: import.meta.env.REACT_APP_ENV,
  integrations: [Sentry.browserTracingIntegration()],
  tracesSampleRate: 1.0,
});

const theme = createTheme();

const root = createRoot(document.getElementById('root')!);

root.render(
  <Provider store={store}>
    <ThemeProvider theme={theme}>
      <App />
    </ThemeProvider>
  </Provider>,
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
