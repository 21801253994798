import classNames from 'classnames';
import React, {
  useEffect,
  useMemo,
  useState,
  useCallback,
  useRef,
  useTransition,
} from 'react';
import styles from './RideBooking.module.scss';
import AppStepper from '../../components/AppStepper/AppStepper';
import { useAppStepper } from '../../components/AppStepper/useAppStepper';
import Button from '../../components/Button';
import { Trans, useTranslation } from 'react-i18next';
import { useConfirmationDialog } from '../../components/ConfirmationDialog/useConfirmationDialog';
import { FormProvider, useForm, useWatch } from 'react-hook-form';
import { ReactComponent as CloseIcon } from '../../images/cancel-current-color.svg?tsx';
import {
  fetchSummary,
  getAvailableTimes,
  getTimeSlots,
  getAvailableDates,
  getFirstAvailableDate,
  createReservation,
  getDatesDiscounts,
} from '../../store/booking/booking.actions';
import {
  SummaryType,
  EAvailableDateStatus,
  TimeSlot,
} from '../../store/booking/booking.types';
import { toastUtil } from '../../utils/toast.utils';
import { Lounge } from '../../store/lounge/lounge.types';
import useWithSelection from '../../hooks/useWithSelection';
import { loungeSelector } from '../../store/lounge/lounge.selectors';
import RideSummary from './components/steps/RideSummary/RideSummary';
import BookingSummary from '../../components/BookingSummary';
import { RIDE_BOOKING_STEPS } from './constants/steps';
import LocationStep from './components/steps/LocationStep/LocationStep';
import useWithDispatch from '../../hooks/useWithDispatch';
import { getLounge } from '../../store/lounge/lounge.actions';
import ExperienceStep from './components/steps/ExperienceStep/ExperienceStep';
import BookingDetailsList, {
  BookingDetail,
} from '../../components/BookingDetailsList/BookingDetailsList';
import { getLoungeAddress } from '../../utils/get-lounge-address.utils';
import BookingPriceDetails, {
  BookingPriceDetail,
} from '../../components/BookingPriceDetails/BookingPriceDetails';
import { round } from '../../utils/round.utils';
import ThankYou from './components/steps/ThankYou/ThankYou';
import LoginDialog from '../../components/LoginDialog/LoginDialog';
import { ERideBookingStep } from './enums/steps';
import { customerSelector } from '../../store/customer/customer.selectors';
import { Customer } from '../../store/customer/customer.types';
import { RIDE_BESTSELLER_DURATION } from './constants/ride-bestseller-duration';
import { timeFromIsoDate } from '../../utils/time-from-iso-date.utils';
import { ICalendarDayProps } from '../../components/Calendar/CalendarDay';
import { ILinerCalendarOnGenerateChunkProps } from '../../components/Calendar/LinearCalendar';
import { formatISO, parseISO, format, isSameDay, isAfter } from 'date-fns';
import { EnhancedDate } from '../../utils/enhanced-date.utils';
import { signUp } from '../../store/customer/customer.actions';
import camelCase from 'lodash/camelCase';
import scrollToError from '@utils/scroll-to-error.utils';
import useDebouncedMemo from '../../hooks/useDebouncedMemo';
import { withCalendarYears } from '@components/Calendar/CalendarYearsProvider';
import isEmpty from 'lodash/isEmpty';
import { useNavigate } from 'react-router-dom';

export enum EBookingPayment {
  lounge,
  card,
}

export interface IRideBookingForm {
  participantsAmount: number;
  loungeId: string;
  timeSlotId: string;
  duration: number;
  date: Date | null;
  time: string;
  coupon: string;
  firstName: string;
  lastName: string;
  email: string;
  payment: EBookingPayment;
  createUser: boolean;
}

const RideBooking: React.FC = () => {
  const navigate = useNavigate();
  const [t, i18n] = useTranslation();
  const [, startTransition] = useTransition();
  const loungeDispatch = useWithDispatch(getLounge);
  const { openDialog, dialog } = useConfirmationDialog();
  const { currentStep, latestVisitedStep, nextStep, prevStep }
    = useAppStepper(RIDE_BOOKING_STEPS, 0);
  const form = useForm<IRideBookingForm>({
    mode: 'onChange',
    shouldUnregister: false,
    defaultValues: {
      participantsAmount: 1,
      loungeId: '',
      timeSlotId: '',
      duration: 0,
      date: null,
      time: '',
      firstName: '',
      lastName: '',
      email: '',
      payment: EBookingPayment.lounge,
      coupon: '',
      createUser: false,
    },
  });
  const lounges: Lounge[] = useWithSelection(loungeSelector);
  const customer: Customer = useWithSelection(customerSelector);
  const [timeSlots, setTimeSlots] = useState<TimeSlot[]>([]);
  const [closestAvailableDate, setClosestAvailableDate] = useState<
    Date | undefined
  >();
  const [datesData, setDatesData] = useState<
    Record<string, Omit<ICalendarDayProps, 'date' | 'selected' | 'onSelect'>>
  >({});
  const [availableTimes, setAvailableTimes] = useState<string[]>([]);
  const [loginOpen, setLoginOpen] = useState<boolean>(false);
  const [isSummary, setIsSummary] = useState<boolean>(false);
  const [isThankYou, setIsThankYou] = useState<boolean>(false);
  const [isFirstAvailableDateLoading, setIsFirstAvailableDateLoading]
    = useState<boolean>(true);
  const [isSummaryLoading, setIsSummaryLoading] = useState<boolean>(true);
  const [isTimesLoading, setIsTimesLoading] = useState<boolean>(true);
  const [isTimeSlotsLoading, setIsTimeSlotsLoading] = useState<boolean>(true);
  const [isSending, setIsSending] = useState<boolean>(false);
  const [priceSummary, setPriceSummary] = useState<SummaryType | null>(null);
  const [experienceDataRefetchCounter, setExperienceDataRefetchCounter] = useState(0);

  const { setValue: setFormValue, setError: setFormError, getValues: getFormValues } = form;

  const previousFormValuesRef = useRef<Partial<IRideBookingForm>>({});

  const {
    email,
    firstName,
    lastName,
    loungeId,
    timeSlotId: currentSelectedTimeSlotId,
    date: currentSelectedDate,
    time: currentSelectedTime,
    participantsAmount: currentSelectedParticipantsAmount,
    coupon,
    createUser,
  } = useWatch({
    control: form.control,
    name: [
      'email',
      'firstName',
      'lastName',
      'loungeId',
      'timeSlotId',
      'participantsAmount',
      'date',
      'time',
      'coupon',
      'createUser',
    ],
  }) as IRideBookingForm;

  const selectedLounge = useMemo(
    () => (loungeId && lounges.find(({ id }) => id === loungeId)) || null,
    [loungeId, lounges],
  );

  const currentSelectedDateAsFullIsoString = useMemo(() => (currentSelectedDate ? formatISO(currentSelectedDate) : ''), [currentSelectedDate]);

  const currentSelectedDateAsShortIsoString = useMemo(() => {
    const [isoDate] = currentSelectedDateAsFullIsoString.split('T');
    return isoDate;
  }, [currentSelectedDateAsFullIsoString]);

  const currentIsGroupRide = useMemo(() => currentSelectedParticipantsAmount > 1, [currentSelectedParticipantsAmount]);

  const currentSelectedTimeSlot = useMemo(
    () => timeSlots.find(({ id }) => id === currentSelectedTimeSlotId),
    [timeSlots, currentSelectedTimeSlotId],
  );

  const {
    debouncedSelectedParticipantsAmount,
    debouncedIsGroupRide,
    debouncedSelectedTimeSlotId,
    debouncedSelectedTimeSlot,
    debouncedSelectedDateAsShortIsoString,
    debouncedSelectedTime,
  } = useDebouncedMemo({
    factory: () => ({
      debouncedSelectedParticipantsAmount: currentSelectedParticipantsAmount,
      debouncedIsGroupRide: currentIsGroupRide,
      debouncedSelectedTimeSlotId: currentSelectedTimeSlotId,
      debouncedSelectedTimeSlot: currentSelectedTimeSlot,
      debouncedSelectedDateAsShortIsoString: currentSelectedDateAsShortIsoString,
      debouncedSelectedTime: currentSelectedTime,
    }),
    wait: 500,
  }, [currentSelectedParticipantsAmount, currentIsGroupRide, currentSelectedTimeSlotId, currentSelectedTimeSlot, currentSelectedDateAsFullIsoString, currentSelectedDateAsShortIsoString, currentSelectedTime]);

  const isTimeSlotsReady = useMemo(
    () => Boolean(loungeId && !isTimeSlotsLoading && !isEmpty(timeSlots)),
    [loungeId, isTimeSlotsLoading, timeSlots],
  );

  const isClosestAvailableDateReady = useMemo(
    () => Boolean(isTimeSlotsReady && debouncedSelectedTimeSlotId && !isFirstAvailableDateLoading && closestAvailableDate),
    [isTimeSlotsReady, debouncedSelectedTimeSlotId, isFirstAvailableDateLoading, closestAvailableDate],
  );

  const isAvailableTimesReady = useMemo(
    () => Boolean(isClosestAvailableDateReady && debouncedSelectedDateAsShortIsoString && !isTimesLoading && !isEmpty(availableTimes)),
    [isClosestAvailableDateReady, debouncedSelectedDateAsShortIsoString, isTimesLoading, availableTimes],
  );

  const isPriceReady = useMemo(
    () => Boolean(isAvailableTimesReady && debouncedSelectedTime && !isSummaryLoading && priceSummary),
    [isAvailableTimesReady, debouncedSelectedTime, isSummaryLoading, priceSummary],
  );

  useEffect(() => {
    previousFormValuesRef.current.time = getFormValues('time');
    previousFormValuesRef.current.date = getFormValues('date');
    previousFormValuesRef.current.duration = getFormValues('duration');
    setIsTimeSlotsLoading(true);
    setPriceSummary(null);
    setDatesData({});
    setClosestAvailableDate(undefined);
    setFormValue('time', '');
    setFormValue('date', null);
    setFormValue('duration', 0);
    setFormValue('timeSlotId', '');
  }, [currentIsGroupRide, experienceDataRefetchCounter, setFormValue, getFormValues]);

  useEffect(() => {
    previousFormValuesRef.current.time = getFormValues('time') || previousFormValuesRef.current.time;
    previousFormValuesRef.current.date = getFormValues('date') || previousFormValuesRef.current.date;
    setIsFirstAvailableDateLoading(true);
    setPriceSummary(null);
    setDatesData({});
    setClosestAvailableDate(undefined);
    setFormValue('time', '');
    setFormValue('date', null);
  }, [currentSelectedTimeSlotId, currentSelectedParticipantsAmount, setFormValue, getFormValues]);

  useEffect(() => {
    previousFormValuesRef.current.time = getFormValues('time') || previousFormValuesRef.current.time;
    setIsTimesLoading(true);
    setPriceSummary(null);
    setFormValue('time', '');
  }, [currentSelectedDateAsFullIsoString, setFormValue, getFormValues]);

  useEffect(() => {
    setIsSummaryLoading(true);
    setPriceSummary(null);
  }, [currentSelectedTime, setFormValue]);

  useEffect(() => {
    if (!debouncedSelectedParticipantsAmount || !loungeId || !debouncedSelectedTimeSlotId || !isTimeSlotsReady) return;

    const abortController = new AbortController();

    getFirstAvailableDate(
      +debouncedSelectedParticipantsAmount,
      debouncedSelectedTimeSlotId,
      loungeId,
      abortController,
    )
      .then((isoDate) => {
        const parsedDate = isoDate ? parseISO(isoDate) : undefined;
        setClosestAvailableDate(parsedDate);
      })
      .catch((error) => {
        const err = error as TRumpApiRequestError;
        if ('message' in err && err.message === 'canceled') return;

        const msg = 'meta' in err ? err.meta.message : err.message;
        if (typeof msg === 'string') toastUtil('error', msg);
      })
      .finally(() => setIsFirstAvailableDateLoading(false));

    return () => {
      abortController.abort();
    };
  }, [debouncedSelectedParticipantsAmount, debouncedSelectedTimeSlotId, loungeId, isTimeSlotsReady]);

  const availableDatesRequestsControllersRef = useRef<Set<AbortController>>(new Set());

  const cancelAllAvailableDatesRequests = useCallback(() => {
    availableDatesRequestsControllersRef.current.forEach((controller) => {
      controller.abort();
      availableDatesRequestsControllersRef.current.delete(controller);
    });
  }, []);

  useEffect(() => {
    return () => {
      cancelAllAvailableDatesRequests();
    };
  }, [currentSelectedTimeSlotId, cancelAllAvailableDatesRequests]);

  const fetchDatesRangeData = useCallback(
    ({ from, to }: ILinerCalendarOnGenerateChunkProps) => {
      startTransition(() => {
        (async () => {
          try {
            const abortController = new AbortController();
            availableDatesRequestsControllersRef.current.add(abortController);

            const [isoFromDate] = formatISO(from).split('T');
            const [isoToDate] = formatISO(to).split('T');

            const [newlyLoadedBookingDates, datesDiscounts] = await Promise.all([
              getAvailableDates(
                +debouncedSelectedParticipantsAmount,
                debouncedSelectedTimeSlotId,
                loungeId,
                isoFromDate,
                isoToDate,
                '',
                abortController,
              ),
              getDatesDiscounts({
                loungeId,
                timeSlotId: debouncedSelectedTimeSlotId,
                fromDate: new EnhancedDate(from).isoDate,
                toDate: new EnhancedDate(to).isoDate,
                isBooking: true,
                abortController,
              }),
            ]);

            availableDatesRequestsControllersRef.current.delete(abortController);

            const newDatesData = newlyLoadedBookingDates.reduce((data, date) => {
              const enhancedDate = new EnhancedDate(parseISO(date.date));

              return {
                ...data,
                [enhancedDate.isoDate]: {
                  barColor: date.usage_color,
                  discount:
                    datesDiscounts && enhancedDate.isoDate in datesDiscounts
                      ? datesDiscounts[enhancedDate.isoDate].tag
                      : undefined,
                  disabled:
                    date.status !== (EAvailableDateStatus.Bookable as string),
                },
              };
            }, {} as Record<string, Omit<ICalendarDayProps, 'date' | 'selected' | 'onSelect'>>);

            setDatesData((prevDatesData) => ({
              ...prevDatesData,
              ...newDatesData,
            }));
          }
          catch (err) {
            const error = err as TRumpApiRequestError;
            if ('message' in error && error.message === 'canceled') return;

            const msg = 'meta' in error ? error.meta.message : error.message;
            if (typeof msg === 'string') toastUtil('error', msg);
          }
        })();
      });
    },
    [debouncedSelectedParticipantsAmount, loungeId, debouncedSelectedTimeSlotId],
  );

  const handleCalendarChunkGeneration = useCallback(
    ({ from, to }: ILinerCalendarOnGenerateChunkProps) => {
      fetchDatesRangeData({ from, to });
    },
    [fetchDatesRangeData],
  );

  useEffect(() => {
    loungeDispatch();
    // eslint-disable-next-line
  }, [i18n.language]);

  useEffect(() => {
    window.scroll({ top: 0 });
  }, [currentStep, isThankYou]);

  useEffect(() => {
    if (!loungeId) return;

    const controller = new AbortController();

    getTimeSlots(debouncedIsGroupRide, loungeId, undefined, controller)
      .then((timeSlots) => {
        setTimeSlots(
          timeSlots.sort(
            (a, b) => a.duration_in_minutes - b.duration_in_minutes,
          ),
        );
      })
      .catch((error) => {
        const err = error as TRumpApiRequestError;
        if ('message' in err && err.message === 'canceled') return;

        const msg = 'meta' in err ? err.meta.message : err.message;
        if (typeof msg === 'string') toastUtil('error', msg);
      })
      .finally(() => setIsTimeSlotsLoading(false));

    return () => controller.abort();
  }, [loungeId, debouncedIsGroupRide, experienceDataRefetchCounter]);

  useEffect(() => {
    if (
      !loungeId
      || !debouncedSelectedParticipantsAmount
      || !debouncedSelectedTimeSlot
      || !debouncedSelectedDateAsShortIsoString
      || !isClosestAvailableDateReady
    )
      return;

    const controller = new AbortController();

    getAvailableTimes(
      loungeId,
      debouncedSelectedTimeSlot.id,
      debouncedSelectedDateAsShortIsoString,
      debouncedSelectedTimeSlot.duration_in_minutes / 60,
      debouncedSelectedParticipantsAmount,
      controller,
    )
      .then((times) => {
        setAvailableTimes(times);
      })
      .catch((error) => {
        const err = error as TRumpApiRequestError;
        if ('message' in err && err.message === 'canceled') return;

        setPriceSummary(null);
        const msg = 'meta' in err ? err.meta.message : err.message;
        if (typeof msg === 'string') toastUtil('error', msg);
      })
      .finally(() => setIsTimesLoading(false));

    return () => controller.abort();
  }, [
    loungeId,
    debouncedSelectedParticipantsAmount,
    debouncedSelectedTimeSlot,
    debouncedSelectedDateAsShortIsoString,
    isClosestAvailableDateReady,
  ]);

  useEffect(() => {
    if (!loungeId || !debouncedSelectedTimeSlotId || !debouncedSelectedTime || !debouncedSelectedParticipantsAmount || !isAvailableTimesReady) return;

    const controller = new AbortController();

    fetchSummary(
      {
        start_time: debouncedSelectedTime,
        time_slot_id: debouncedSelectedTimeSlotId,
        simulators: debouncedSelectedParticipantsAmount,
        lounge_id: loungeId,
        rides_sharing: false,
        coupon_code: coupon,
      },
      controller,
    )
      .then(({ data }) => setPriceSummary(data ?? null))
      .catch((error) => {
        const err = error as TRumpApiRequestError;
        if ('message' in err && err.message === 'canceled') return;

        setPriceSummary(null);
        const msg = 'meta' in err ? err.meta.message : err.message;
        if (typeof msg === 'string') toastUtil('error', msg);
      })
      .finally(() => setIsSummaryLoading(false));

    return () => controller.abort();
  }, [loungeId, debouncedSelectedTimeSlotId, debouncedSelectedTime, debouncedSelectedParticipantsAmount, coupon, customer, isAvailableTimesReady]);

  const onExit = () =>
    openDialog({
      okText: 'booking.exit',
      okIcon: <CloseIcon width={16} />,
    }).then((isConfirmed) => {
      if (!isConfirmed) return;

      if (customer) {
        navigate('/');
      }
      else {
        window.open(import.meta.env.REACT_APP_WEBSITE_URL, '_self');
      }
    });

  const openLogin = () => setLoginOpen(true);

  const summaryDetails = useMemo(() => {
    const loungeAddress = selectedLounge
      ? getLoungeAddress(selectedLounge)
      : '';

    return [
      {
        label: 'booking.steps.summary.where',
        value: selectedLounge?.name,
        caption: loungeAddress,
      },
      {
        label: 'booking.participants',
        value: currentSelectedParticipantsAmount,
      },
      {
        label: 'booking.steps.summary.when',
        value: currentSelectedTime
          ? `${format(new Date(currentSelectedTime), 'do MMMM yyyy')},\n${
            timeFromIsoDate(currentSelectedTime)?.[0] ?? ''
          }`
          : '',
      },
    ] as BookingDetail[];
  }, [currentSelectedParticipantsAmount, selectedLounge, currentSelectedTime]);

  const priceDetails: BookingPriceDetail[] = useMemo<
    BookingPriceDetail[]
  >(() => {
    if (!priceSummary) return [];

    const discounts = priceSummary
      ? priceSummary.products.flatMap(({ discounts }) => discounts)
      : [];
    const mappedDiscount = discounts.length
      ? discounts.reduce((acc, discount) => {
        const value = +(acc?.[discount.name] || '') + +discount.price_effect;
        acc[discount.name] = value.toString();

        return acc;
      }, {} as Record<string, string>)
      : null;

    const details: BookingPriceDetail[] = [
      ...priceSummary.products.map(({ product_name, base_price }) => {
        return {
          label: `${debouncedSelectedParticipantsAmount} x ${product_name}`,
          value: base_price ? round(base_price) : '',
        } as BookingPriceDetail;
      }),
    ];

    if (mappedDiscount) {
      const discountDetails: BookingPriceDetail[] = Object.entries(mappedDiscount).map(([label, value]) => ({
        label,
        value: round(value),
      }));
      details.push(...discountDetails);
    }

    return details;
    // eslint-disable-next-line
  }, [priceSummary, isSummary, t]);

  const [newlyCreatedUserData, setNewlyCreatedUserData] = useState<Customer>();

  const handleBookingSubmit = useCallback(
    async (cardToken: string) => {
      setIsSending(true);

      try {
        let newUser: Customer | undefined = newlyCreatedUserData;

        if (!customer && !newUser) {
          const response = await signUp({
            email,
            first_name: firstName,
            last_name: lastName,
            silent_member: true,
            create_account: createUser,
          });
          newUser = response.data;
          setNewlyCreatedUserData(newUser);
        }

        const user = customer ? customer : newUser;

        await createReservation(
          {
            start_time: debouncedSelectedTime,
            time_slot_id: debouncedSelectedTimeSlotId,
            simulators: debouncedSelectedParticipantsAmount,
            lounge_id: loungeId,
            coupon_code: coupon || null,
            rides_sharing: false,
            card_token: cardToken,
            paid_in_cash: !cardToken,
            customer_id: user ? user.id : undefined,
          },
          true,
        );

        setNewlyCreatedUserData(undefined);
        setIsThankYou(true);
      }
      catch (err) {
        const error = err as TRumpApiErrorResponseData;
        if (!error || !error.meta) return;

        if (error?.meta?.errors) {
          error.meta.errors.forEach(
            ({ field, messages }: { field: string; messages: string[] }) => {
              const camelCaseName = camelCase(field);
              setFormError(camelCaseName as keyof IRideBookingForm, {
                type: 'manual',
                message: messages[0],
              });
            },
          );
          toastUtil('error', t('booking.steps.summary.billingInfoError'));
          setTimeout(() => scrollToError());
        }

        if (typeof error?.meta?.message === 'string') {
          toastUtil('error', error.meta.message);
        }
      }

      setIsSending(false);
    },
    [
      setFormError,
      newlyCreatedUserData,
      customer,
      email,
      firstName,
      lastName,
      createUser,
      debouncedSelectedTimeSlotId,
      debouncedSelectedParticipantsAmount,
      debouncedSelectedTime,
      coupon,
      loungeId,
      t,
    ],
  );

  /**
   * Data & deps cleanup callbacks.
   */

  const resetExperienceStepFormValues = useCallback(() => {
    setFormValue('time', '');
    setFormValue('date', null);
    setFormValue('timeSlotId', '');
    setFormValue('participantsAmount', 1);
  }, [setFormValue]);

  const clearTimeSlotsAndDependantData = useCallback(() => {
    // Clearing in reverse order to ensure that updates will be applied in correct sequence.
    setPriceSummary(null);
    setAvailableTimes([]);
    setDatesData({});
    setClosestAvailableDate(undefined);
    setTimeSlots([]);
  }, []);

  const handleLoungeSelect = useCallback((lounge: Lounge) => {
    if (lounge.id !== loungeId) {
      resetExperienceStepFormValues();
      clearTimeSlotsAndDependantData();
    }
    setFormValue('loungeId', lounge.id);
    nextStep();
  }, [loungeId, resetExperienceStepFormValues, clearTimeSlotsAndDependantData, setFormValue, nextStep]);

  /**
   * Form update effects after related data fetched.
   */

  useEffect(() => {
    if (!timeSlots.length) return;

    const preferredTimeSlotDuration = previousFormValuesRef.current.duration || RIDE_BESTSELLER_DURATION;

    const timeSlot
    = timeSlots.find(
      ({ duration_in_minutes }) => duration_in_minutes === preferredTimeSlotDuration,
    ) || timeSlots[0];

    setFormValue('timeSlotId', timeSlot.id);
  }, [setFormValue, timeSlots]);

  useEffect(() => {
    if (!timeSlots.length) return;
    const timeSlot = debouncedSelectedTimeSlotId ? timeSlots.find((timeSlot) => timeSlot.id === debouncedSelectedTimeSlotId) : null;
    setFormValue('duration', timeSlot?.duration_in_minutes ?? RIDE_BESTSELLER_DURATION);
  }, [setFormValue, timeSlots, debouncedSelectedTimeSlotId]);

  useEffect(() => {
    if (!closestAvailableDate) return;
    const preferredDate = new EnhancedDate(previousFormValuesRef.current.date || closestAvailableDate);

    const isPreferredDateCorrect = isSameDay(preferredDate, closestAvailableDate)
      || isAfter(preferredDate, closestAvailableDate);

    const newSelectedDate = isPreferredDateCorrect ? preferredDate : closestAvailableDate;

    setFormValue('date', newSelectedDate);
  }, [setFormValue, closestAvailableDate]);

  useEffect(() => {
    if (!(currentSelectedDateAsShortIsoString in datesData)) return;
    if (!datesData[currentSelectedDateAsShortIsoString].disabled) return;
    setFormValue('date', closestAvailableDate);
  }, [currentSelectedDateAsShortIsoString, datesData, closestAvailableDate, setFormValue]);

  useEffect(() => {
    if (!availableTimes.length) return;

    const parsedIsoTime = timeFromIsoDate(previousFormValuesRef.current.time ?? '')?.[0];

    const foundPreferredTime = availableTimes.find((availableTime) => availableTime.includes(`T${parsedIsoTime}`));

    const newSelectedTime = foundPreferredTime ?? availableTimes[0];

    setFormValue('time', newSelectedTime);
  }, [setFormValue, availableTimes]);

  /**
   * Specific re-fetches.
   */

  const isExperienceDataRequiresRefetchRef = useRef(false);
  const currentStepRef = useRef(currentStep);
  currentStepRef.current = currentStep;

  useEffect(() => {
    setIsSummaryLoading(true);
    setPriceSummary(null);
    if (currentStepRef.current !== 1) {
      isExperienceDataRequiresRefetchRef.current = true;
    }
    else {
      setExperienceDataRefetchCounter((value) => value + 1);
    }
  }, [customer]);

  useEffect(() => {
    /**
     * Triggers data refetch once user logs out on other steps
     * & get back to experience step.
     */
    if (currentStep !== 1) return;
    if (!isExperienceDataRequiresRefetchRef.current) return;
    isExperienceDataRequiresRefetchRef.current = false;
    setExperienceDataRefetchCounter((value) => value + 1);
  }, [currentStep, setFormValue]);

  const getStepComponent = () => {
    if (isThankYou)
      return (
        <ThankYou
          selectedLounge={selectedLounge}
          summaryDetails={summaryDetails}
        />
      );
    if (isSummary)
      return (
        <RideSummary
          selectedLounge={selectedLounge}
          summaryDetails={summaryDetails}
          priceDetails={priceDetails}
          priceSummary={priceSummary}
          isPriceLoading={!isPriceReady}
          isSending={isSending}
          toggleSummary={() => {
            setIsSummary(false);
            prevStep();
          }}
          openLogin={openLogin}
          onSubmit={handleBookingSubmit}
        />
      );
    if (currentStep === 0) return <LocationStep onSelectLounge={handleLoungeSelect} />;
    if (currentStep === 1)
      return (
        <ExperienceStep
          selectedLounge={selectedLounge}
          onForward={() => {
            setIsSummary(true);
            nextStep();
          }}
          onBackward={() => {
            prevStep();
          }}
          closestAvailableDate={closestAvailableDate}
          datesData={datesData}
          onDatesRangeDataRequest={handleCalendarChunkGeneration}
          priceSummary={priceSummary}
          priceDetails={priceDetails}
          summaryDetails={summaryDetails}
          availableTimes={availableTimes}

          isTimeSlotsReady={isTimeSlotsReady}
          isClosestAvailableDateReady={isClosestAvailableDateReady}
          isAvailableTimesReady={isAvailableTimesReady}
          isPriceReady={isPriceReady}

          timeSlots={timeSlots}
          currency={priceSummary ? priceSummary.currency : undefined}
          vat={
            priceSummary && priceSummary.products[0]
              ? priceSummary.products[0].vat
              : undefined
          }
        />
      );
  };

  const isExperienceStep
    = RIDE_BOOKING_STEPS[currentStep].key
    === (ERideBookingStep.experience as string);

  return (
    <FormProvider {...form}>
      <div
        className={classNames(styles.rideBooking, {
          [styles.rideBooking_scrollable]: isSummary,
        })}
      >
        {isSummary || isThankYou
          ? (
              ''
            )
          : (
              <div className={styles.header}>
                <div className={classNames('container', styles.header__content)}>
                  <AppStepper
                    className={styles.header__stepper}
                    steps={RIDE_BOOKING_STEPS}
                    currentStep={currentStep}
                  />
                  <Button
                    appearance="outline"
                    className={styles.header__button}
                    label={t('booking.exit')}
                    icon={<CloseIcon width={16} />}
                    onClick={onExit}
                  />
                </div>
              </div>
            )}
        <div className={styles.wrapper}>
          <div className={styles.content}>
            <div
              className={classNames(styles.cardWrapper, {
                [styles.cardWrapper_scrollable]: isExperienceStep && !isSummary,
              })}
              data-step={!isThankYou && RIDE_BOOKING_STEPS[currentStep].key}
            >
              <div className={styles.cardWrapper__card}>
                {getStepComponent()}
              </div>
              {!customer && !isSummary && !isThankYou
                ? (
                    <div className={styles.cardWrapper__caption}>
                      <Trans
                        i18nKey="auth.logIn.caption"
                        components={{
                          button: <span onClick={() => openLogin()} />,
                        }}
                      />
                    </div>
                  )
                : (
                    ''
                  )}
            </div>
            {isExperienceStep && !isSummary
              ? (
                  <div className={styles.content__sidebar}>
                    <BookingSummary
                      currency={priceSummary?.currency ?? 'CHF'}
                      total={
                        isPriceReady && priceSummary?.products?.[0]
                          ? round(priceSummary.products[0].final_price)
                          : '-'
                      }
                      vat={
                        isPriceReady && priceSummary?.products?.[0]
                          ? priceSummary.products[0].vat
                          : '-'
                      }
                      isLoading={!isPriceReady}
                    >
                      <h3 className={styles.content__title}>
                        {t('booking.summary')}
                      </h3>
                      {latestVisitedStep > 0 && summaryDetails
                        ? (
                            <BookingDetailsList details={summaryDetails} />
                          )
                        : (
                            ''
                          )}

                      {latestVisitedStep > 0 && summaryDetails
                        ? (
                            <h3 className={classNames(styles.content__title, styles.content__title_mb8)}>
                              {t('booking.price')}
                            </h3>
                          )
                        : (
                            ''
                          )}

                      {!isPriceReady
                        ? '-'
                        : (
                            <BookingPriceDetails
                              details={priceDetails}
                              emptyStateKey="booking.rideSummaryEmptyState"
                            />
                          )}
                    </BookingSummary>
                  </div>
                )
              : (
                  ''
                )}
          </div>
        </div>

        <LoginDialog open={loginOpen} onClose={() => setLoginOpen(false)} />
        {dialog}
      </div>
    </FormProvider>
  );
};

export default withCalendarYears(RideBooking);
